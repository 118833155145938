<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar class="toolbar-header">
        <div class="d-flex align-center px-2 my-2">
          <ion-buttons slot="start">
            <ion-back-button
              color="primary"
              mode="ios"
              text=""
              default-href="/b2b/notifications"
              @click="$router.back()"
              :icon="chevronBackOutline"
            ></ion-back-button>
          </ion-buttons>
          <ion-label class="fw-600 pl-1 fs-3" @click="$router.back()"
            >{{ $t('quotation_notification') }}
            <ion-note v-if="badgeHeader" class="fs-1 ml-1 badge">{{ badgeHeader }}</ion-note>
          </ion-label>
        </div>
      </ion-toolbar>
      <ion-segment mode="ios" v-model="selectedSegment">
        <ion-segment-button v-for="(segment, idx) in segmentOptions" :key="idx" :value="segment.value">
          <ion-label>{{ segment.label }}</ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-header>
    <!-- // all  -->
    <ion-content v-if="selectedSegment === QUOTATION_STATUSSES.ALL" :scroll-events="true">
      <ion-refresher slot="fixed" @ionRefresh="handleRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <pull-refresh v-if="!isOnline"></pull-refresh>
      <skeleton-notifications v-if="isLoading" />
      <div v-else>
        <comp-not-found style="margin-top: 5rem" v-if="allQuotations?.length <= 0" :useAction="true" />
        <div v-else>
          <comp-mark-all-as-read />
          <card-notification v-for="(notif, idx) in allQuotations || []" :key="idx" :data="notif" />
        </div>
      </div>
      <ion-infinite-scroll
        @ionInfinite="loadMore($event)"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabledLoadMore || allQuotations?.length <= 0"
      >
        <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading ...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>

    <!-- // approved  -->
    <ion-content v-if="selectedSegment === QUOTATION_STATUSSES.APPROVED" :scroll-events="true">
      <ion-refresher slot="fixed" @ionRefresh="handleRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <pull-refresh v-if="!isOnline"></pull-refresh>
      <skeleton-notifications v-if="isLoadingApproved" />
      <div v-else>
        <comp-not-found
          style="margin-top: 5rem"
          v-if="quotationApprovedList?.length <= 0"
          :useAction="true"
        />
        <div v-else>
          <card-quotation
            :isMerchantUser="true"
            v-for="(notif, idx) in quotationApprovedList"
            :key="idx"
            :data="notif"
          />
        </div>
      </div>
      <ion-infinite-scroll
        @ionInfinite="loadMoreApproved($event)"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabledApprovedList || quotationApprovedList?.length <= 0"
      >
        <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading ...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>

    <!-- // rejected  -->
    <ion-content v-if="selectedSegment === QUOTATION_STATUSSES.REJECTED" :scroll-events="true">
      <ion-refresher slot="fixed" @ionRefresh="handleRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <pull-refresh v-if="!isOnline"></pull-refresh>
      <skeleton-notifications v-if="isLoadingRejected" />
      <div v-else>
        <comp-not-found
          style="margin-top: 5rem"
          v-if="quotationRejectedList?.length <= 0"
          :useAction="true"
        />
        <div v-else>
          <card-quotation
            :isMerchantUser="true"
            v-for="(notif, idx) in quotationRejectedList"
            :key="idx"
            :data="notif"
          />
        </div>
      </div>
      <ion-infinite-scroll
        @ionInfinite="loadMoreRejected($event)"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabledRejectedList || quotationRejectedList?.length <= 0"
      >
        <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading ...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>
<script>
import CardNotification from '@/modules/shared/components/notifications/CardNotification.vue';
import CardQuotation from '@/modules/shared/components/notifications/CardQuotation.vue';
import CompMarkAllAsRead from '@/modules/shared/components/notifications/CompMarkAllAsRead.vue';
import CompNotFound from '@/modules/shared/components/notifications/NotFoundNotification.vue';
import SkeletonNotifications from '@/modules/shared/components/notifications/SkeletonNotifications.vue';

import { QUOTATION_STATUSSES } from '@/modules/shared/constants';
import { usePullToRefreshPage } from '@/usecases/global';
import { useQuotationListNotification } from '@/usecases/notifications';
import { chevronBackOutline } from 'ionicons/icons';
import { defineAsyncComponent, defineComponent } from 'vue';

export default defineComponent({
  name: 'B2bNotifQuotationsUpdates',
  components: {
    CardQuotation,
    CardNotification,
    CompNotFound,
    SkeletonNotifications,
    CompMarkAllAsRead,
    PullRefresh: defineAsyncComponent(() => import('@/modules/shared/views/refreshPage/PullRefresh.vue'))
  },
  setup() {
    const {
      selectedSegment,
      loadMore,
      isLoadingApproved,
      isLoadingPending,
      isLoadingRejected,
      fetchData,
      fetchPendingQuotation,
      fetchApprovedQuotation,
      fetchRejectedQuotation,
      badgeHeader,
      isLoading,
      isDisabledLoadMore,
      allQuotations,
      quotationApprovedList,
      quotationRejectedList,
      segmentOptions,
      loadMoreRejected,
      isDisabledRejectedList,
      loadMoreApproved,
      isDisabledApprovedList
    } = useQuotationListNotification();
    const { isOnline, handleRefreshAndRefetchData } = usePullToRefreshPage();

    const handleRefresh = (event) => {
      let fetchFunction;
      switch (selectedSegment.value) {
        case QUOTATION_STATUSSES.REJECTED:
          fetchFunction = fetchRejectedQuotation;
          break;

        case QUOTATION_STATUSSES.PENDING:
          fetchFunction = fetchPendingQuotation;
          break;

        case QUOTATION_STATUSSES.APPROVED:
          fetchFunction = fetchApprovedQuotation;
          break;

        default:
          fetchFunction = fetchData;
          break;
      }

      handleRefreshAndRefetchData(event, fetchFunction);
    };
    return {
      isLoadingApproved,
      isLoadingPending,
      isLoadingRejected,
      loadMoreApproved,
      isDisabledApprovedList,
      loadMoreRejected,
      isDisabledRejectedList,
      badgeHeader,
      segmentOptions,
      isLoading,
      isDisabledLoadMore,
      allQuotations,
      quotationApprovedList,
      quotationRejectedList,
      selectedSegment,
      isOnline,
      handleRefresh,
      QUOTATION_STATUSSES,
      loadMore,
      chevronBackOutline
    };
  }
});
</script>

<style lang="scss" scoped>
.badge {
  background-color: var(--ion-color-danger);
  border-radius: 0.3rem;
  color: #fff;
  font-weight: 600;
  margin: auto;
  padding: 3px 5px;
}
ion-segment {
  background-color: #fff !important;
}
ion-segment-button::part(indicator-background) {
  display: none;
}
ion-segment-button.ios::part(indicator-background) {
  display: none;
}
// ios styles
ion-segment-button.ios::part(native) {
  background: #fff;
  border-bottom: 2px solid rgb(169, 169, 169);
}

.segment-button-checked.ios::part(native) {
  color: var(--ion-color-text-primary-green-600);
  border-bottom: 2px solid var(--ion-color-text-primary-green-600);
}
</style>
